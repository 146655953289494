
import moment from 'moment'
import { defineComponent, ref, onMounted } from 'vue'
import ApiService from '@/core/services/ApiService'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Teacher-list',
  components: {},
  props: {
    id: String,
  },
  setup() {
    const { t } = useI18n()
    const store = useStore()

    const filteredNotifications = ref<[]>([])
    const notificationsList = ref<[]>([])

    const loading = ref<boolean>(true)

    onMounted(async () => {
      ApiService.get('/notifications')
        .then(({ data }) => {
          data = data.map((notif) => {
            if (notif.refType == 'newHomeworkToValidate')
              notif.link = '/exercisesValidation/' + notif.ref
            else if (notif.refType == 'newCourseToValidate')
              notif.link = '/coursesValidation/' + notif.ref
            else if (notif.refType == 'newMeeting')
              notif.link = '/teacher/meetings'
            else if (notif.refType == 'newResearch')
              notif.link = '/teacher/research/' + notif.ref
            else if (notif.refType == 'StudentPunishmentRejected')
              notif.link =
                '/student/' + notif.ref.split('#')[1] + '/punishments'
            else if (notif.refType == 'newValidatedHomework')
              notif.link = '/exercise/' + notif.ref
            else if (notif.refType == 'newSubmitedHomework')
              notif.link = '/exercise/' + notif.ref
            else if (notif.refType == 'newValidatedCourse')
              notif.link = '/course/' + notif.ref
            else notif.link = '/'
            return notif
          })

          notificationsList.value = data
          filteredNotifications.value = data
        })
        .catch((e) => console.log(e))
        .finally(() => {
          loading.value = false
        })

      setCurrentPageBreadcrumbs(t('notification.notifications'), [])
    })

    return {
      t,
      moment,
      apiUrl: store.getters.serverConfigUrl.base_url + '/',
      loading,
      filteredNotifications,
      notificationsList,
    }
  },
})
